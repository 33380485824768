export const data = [
  {
    author: "Paul Graham",
    imageFilename: "ll7",
    title: "What You'll Wish You'd Known",
    href: "http://www.paulgraham.com/hs.html",
  },
  {
    author: "Seymour Papert",
    imageFilename: "ll7",
    title: "Mindstorms",
    href: "https://en.wikipedia.org/wiki/Mindstorms_(book)",
  },
  {
    author: "Richard W. Hamming",
    imageFilename: "e33",
    title: "The Art of Doing Science and Engineering - Learning to Learn",
    href: "https://www.youtube.com/watch?v=AD4b-52jtos",
  },
  {
    author: "Marvin Minsky",
    imageFilename: "rt3",
    title: "Essays on Education --- (for OLPC)",
    href: "http://wiki.laptop.org/go/Marvin_Minsky_essays",
  },
];
