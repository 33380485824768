import {
  Author,
  EntryWrapper,
  InnerWrapper,
  StyledIcon,
  StyledImage,
  StyledLinkTitle,
  TitleWrapper,
} from "./styles";
import ExternalLinkIcon from "./external-link-icon";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { useNewsThumbnails } from "@queries/use-news-thumbnails";

const handleIconClick = (href) => {
  window.open(href);
};

const NewsEntry = ({ href, title, imageFilename, author, ...rest }) => {
  const { getThumbnailFluidImage } = useNewsThumbnails();

  return (
    <EntryWrapper {...rest}>
      <StyledImage>
        <Img
          style={{ borderRadius: 5 }}
          fluid={getThumbnailFluidImage(imageFilename)}
          alt={title}
        />
      </StyledImage>
      <InnerWrapper>
        <TitleWrapper>
          <a
            href={href}
            title={title}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }}
          >
            <StyledLinkTitle>{title}</StyledLinkTitle>
          </a>
          <StyledIcon onClick={() => handleIconClick(href)}>
            <ExternalLinkIcon />
          </StyledIcon>
        </TitleWrapper>
        <Author>{author}</Author>
      </InnerWrapper>
    </EntryWrapper>
  );
};

NewsEntry.propTypes = {
  author: PropTypes.string,
  imageFilename: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
};

export default NewsEntry;
