import styled from "styled-components";

export const EntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
`;

export const InnerWrapper = styled.div`
  margin-left: 40px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLinkTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Author = styled.span`
  display: block;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledImage = styled.div`
  min-width: 96px;
  width: 96px;
  height: 96px;
`;

export const StyledIcon = styled.div`
  margin-bottom: 2px;
  margin-left: 12px;
  fill: #e43f5a;
  &:hover {
    cursor: pointer;
  }
`;

export const MobileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 30px;
  row-gap: 35px;
`;

export const MobileEntryWrapper = styled.div`
  &:active {
    outline: #060038 solid 1px;
  }
`;

export const SubtitleWrapper = styled.div`
  margin-top: 34px;
`;

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
