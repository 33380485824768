import { graphql, useStaticQuery } from "gatsby";
import { isValidString } from "@utilities/string";

export const useNewsThumbnails = () => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            relativeDirectory: { eq: "news-thumbnails" }
            sourceInstanceName: { eq: "images" }
          }
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  );

  const getThumbnailFluidImage = (name) => {
    if (data.images?.nodes.length === 0 || !isValidString(name)) {
      return null;
    }
    // NOTE: The search for the image by name, in this case, is Case Sensitive
    const image = data.images?.nodes.find((node) => node.name === name);

    return image?.childImageSharp?.fluid || null;
  };

  return {
    getThumbnailFluidImage,
  };
};
