import styled, { css } from "styled-components";
import Img from "gatsby-image";
import LayoutCenterColumn from "@components/layout-center-column";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
`;

const ContentBox = styled.div`
  flex: 1;
  max-width: 444px;
  margin-right: 30px;
  align-self: center;

  @media (max-width: 768px) {
    max-width: unset;
    margin-right: unset;
  }
`;

const Content = styled.div``;

const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const Media = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    max-height: 300px;

    img {
      max-height: 300px;
    }
  }
`;

const BG = styled.div`
  padding-top: 89px;
  max-width: 1440px;
  margin: 0 auto;

  ${(props) => {
    if (props.color) {
      return css`
        background: linear-gradient(
          to right,
          #ffffff 0%,
          #ffffff 50%,
          ${props.color} 50%,
          ${props.color} 100%
        );

        @media (max-width: 1110px) {
          padding-top: 69px;
          background: linear-gradient(
            to right,
            #ffffff 0%,
            #ffffff 55%,
            ${props.color} 55%,
            ${props.color} 100%
          );
        }

        @media (max-width: 990px) {
          padding-top: 49px;
          background: linear-gradient(
            to right,
            #ffffff 0%,
            #ffffff 60%,
            ${props.color} 60%,
            ${props.color} 100%
          );
        }
      `;
    }
  }}

  @media (max-width: 768px) {
    background: none;
  }
`;

const Headline = (props) => {
  const { getFluidImageName } = useImage();

  return (
    <BG color={props.bgColor}>
      <LayoutCenterColumn>
        <Wrapper>
          <ContentBox>
            <Content>
              <Title>{props.title}</Title>
              <Description>{props.description}</Description>
            </Content>
          </ContentBox>
          <Media>
            <StyledImg
              fluid={getFluidImageName(props.imageFilename)}
              alt="Pessoas em cima da montanha"
            />
          </Media>
        </Wrapper>
      </LayoutCenterColumn>
    </BG>
  );
};

Headline.defaultProps = {
  title: "Title",
  description: "Description",
  imageFilename: null,
  bgColor: "#0080a7",
};

Headline.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageFilename: PropTypes.string,
  bgColor: PropTypes.string,
};

export default Headline;
