import {
  Author,
  MobileEntryWrapper,
  StyledLinkTitle,
  SubtitleWrapper,
} from "./styles";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { useNewsThumbnails } from "@queries/use-news-thumbnails";

const handleMobileIconClick = (href) => {
  window.location.assign(href);
};

const MobileNewsEntry = ({ href, title, imageFilename, author, ...rest }) => {
  const { getThumbnailFluidImage } = useNewsThumbnails();

  return (
    <MobileEntryWrapper {...rest} onClick={() => handleMobileIconClick(href)}>
      <Img
        style={{ borderRadius: 5 }}
        fluid={getThumbnailFluidImage(imageFilename)}
        alt={title}
      />
      <SubtitleWrapper>
        <StyledLinkTitle>{title}</StyledLinkTitle>
        <Author>{author}</Author>
      </SubtitleWrapper>
    </MobileEntryWrapper>
  );
};

MobileNewsEntry.propTypes = {
  author: PropTypes.string,
  imageFilename: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
};

export default MobileNewsEntry;
