import { CTAWrapper, MobileWrapper } from "./styles";
import Button from "@components/button";
import EmptySpace from "@components/empty-space/component";
import MobileNewsEntry from "./mobile-news-entry";
import NewsEntry from "./news-entry";
import PropTypes from "prop-types";
import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const NewsCollection = (props) => {
  const breakpoints = useBreakpoint();
  const [collectionSize, setCollectionSize] = React.useState(8);

  const handleShowMore = () => {
    if (props.collection.length > collectionSize) {
      setCollectionSize((size) => size + 5);
    }
  };

  if (breakpoints.mobile) {
    return (
      <React.Fragment>
        <MobileWrapper>
          {props.collection.slice(0, collectionSize).map((entry) => {
            const key = String(entry.title)
              .replace(" ", "")
              .toLocaleLowerCase();
            return <MobileNewsEntry key={key} {...entry} />;
          })}
        </MobileWrapper>

        <EmptySpace mobile={{ margin: 50 }} />

        {props.collection.length > collectionSize && (
          <CTAWrapper>
            <Button
              onClick={handleShowMore}
              size="large"
              color="white"
              bg="orange"
              border="none"
            >
              carregar mais
            </Button>
          </CTAWrapper>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {props.collection.slice(0, collectionSize).map((entry) => {
        const key = String(entry.title).replace(" ", "").toLocaleLowerCase();
        return <NewsEntry key={key} {...entry} />;
      })}

      {props.collection.length > collectionSize && (
        <CTAWrapper>
          <Button
            onClick={handleShowMore}
            size="large"
            color="white"
            bg="orange"
            border="none"
          >
            carregar mais
          </Button>
        </CTAWrapper>
      )}
    </React.Fragment>
  );
};

NewsCollection.propTypes = {
  collection: PropTypes.arrayOf({
    author: PropTypes.string,
    imageFilename: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default NewsCollection;
