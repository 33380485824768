import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Headline from "@components/headline/headline-component";
import InspirationalNewsCollection from "@sections/inspirational-news-collection/component";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";

export default function Inspiracao() {
  return (
    <Layout isHeroAvailable={false}>
      <FullBleed>
        <Headline
          title="Ideas matter. Actions matter. São ambas importantes."
          description="Em grande medida, o que queremos fazer no TreeTree2 é por em ação as
          ideias que nos inspiraram, algumas das quais listamos aqui."
          bgColor="#060038"
          imageFilename="hand-light-bulb"
        />
      </FullBleed>

      <EmptySpace desktop={{ margin: 150 }} mobile={{ margin: 80 }} />

      <InspirationalNewsCollection />

      <EmptySpace desktop={{ margin: 150 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="Inspirações do TreeTree2"
        description="Descobre as ideias que inspiram as ações do TreeTree2!"
      />
    </Layout>
  );
}
